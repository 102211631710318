import { gql, request } from "graphql-request"
import { PodcastAttributes } from "graphql/podcastAttributes"
import { useInfiniteQuery } from "react-query"

// Query name
export const podcastsQueryName = "podcastsQuery"

// GraphQL query
const query = gql`
  ${PodcastAttributes()}

  query PodcastsQuery($storeCountry: String!, $pageSize: Int!, $offset: Int!) {
    podcasts(
      pageSize: $pageSize
      storeCountry: $storeCountry
      offset: $offset
      orderBy: [{ key: "title", direction: ASC }]
    ) {
      nodes {
        ...PodcastAttributes
      }
      pageInfo {
        hasMore
      }
    }
  }
`

//
// Query for podcasts from the content system
//
export const podcastsQuery = async ({ queryKey, pageParam = 0 }) => {
  const [, { storeCountry, breakpoint }] = queryKey
  let pageSize = 0
  switch (breakpoint) {
    case "desktop":
    case "laptop":
      pageSize = 24
      break
    case "tablet":
      pageSize = 18
      break
    case "mobile":
      pageSize = 12
      break
    default:
      throw new Error(`Unrecognised breakpoint: ${breakpoint}`)
  }
  try {
    // Run GraphQL query to get content items
    const podcasts = await request(
      process.env.GATSBY_GRAPHQL_CONTENT_ENDPOINT,
      query,
      {
        storeCountry: storeCountry,
        pageSize: pageSize,
        offset: pageParam,
      }
    )
    return podcasts
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use the podcasts content query
//
export const usePodcastsQuery = (storeCountry, breakpoint) => {
  return useInfiniteQuery(
    [podcastsQueryName, { storeCountry, breakpoint }],
    podcastsQuery,
    {
      staleTime: Infinity,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.podcasts?.pageInfo?.hasMore) {
          return pages.length * lastPage.podcasts.nodes.length
        } else {
          return undefined
        }
      },
      enabled: storeCountry !== undefined && breakpoint !== undefined,
    }
  )
}
