import { useLocation } from "@reach/router"
import ContentCard from "components/contentCard/contentCard"
import Button from "components/controls/button"
import Metadata from "components/utils/metadata/metadata"
import ModalDialog from "components/utils/modalDialog"
import Spinner from "components/utils/spinner"
import BreakpointContext from "context/breakpointContext"
import { ContentItemsProvider } from "context/contentItemsContext"
import { useCountries } from "context/countryContext"
import { Screen, useSetScreen } from "context/screenContext"
import { useContentItemsMap } from "hooks/useContentItemsMap"
import { useFooter } from "hooks/useFooter"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import { useTabBar } from "hooks/useTabBAr"
import GridLayout from "layouts/gridLayout"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { usePodcastsQuery } from "reactQuery/queries/podcastsQuery"

const StorePodcasts = ({ pageContext }) => {
  // Set the screen
  useSetScreen(Screen.STORE)

  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Country information from context
  const { storeCountry } = useCountries()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Query for podcasts
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = usePodcastsQuery(storeCountry, bpt)

  // Join all pages of podcasts together
  const podcasts = useMemo(() => {
    let podcasts = []
    podcasts = data?.pages?.flatMap((page) => {
      return page.podcasts?.nodes?.flatMap((podcast) => {
        return podcast
      })
    })
    return podcasts
  }, [data])

  // Build a map of podcast content items
  const contentItemsMap = useContentItemsMap(podcasts)

  // Error state
  const [showError, setShowError] = useState(false)

  // Mount state
  const isMounted = useMountOnLoad()

  // Try and extract the selected item from the page context, if
  // this page has been built statically.
  const selectedItem = pageContext?.selectedItem

  // Generate the page title
  const pageTitle = useMemo(() => {
    if (selectedItem) {
      return `${selectedItem.title} | Podcasts`
    } else {
      return "Podcasts"
    }
  }, [selectedItem])

  // Use cover art for the metadata image if there is a selected item
  const metadataImage = useMemo(() => {
    if (selectedItem && selectedItem.artwork) {
      return selectedItem.artwork
    } else {
      return undefined
    }
  }, [selectedItem])

  // Only display footer when the podcasts have finished loading
  useFooter(isMounted && data !== undefined)

  // Use a Tab Bar
  useTabBar(true)

  useEffect(() => {
    if (error) {
      setShowError(true)
    }
  }, [error])

  return (
    <>
      {/* eslint-disable react/jsx-pascal-case */}
      <Metadata
        title={pageTitle}
        description={selectedItem ? selectedItem.description : undefined}
        image={metadataImage}
        pathname={location.pathname}
        useStrapLine={selectedItem ? false : true}
      />

      {/* Content */}
      <GridLayout
        type="fluid"
        container={false}
        offsets={false}
        className="col-span-full"
      >
        <ContentItemsProvider contentItemsMap={contentItemsMap}>
          {/* Podcasts */}
          {podcasts?.map((podcast) => {
            return <ContentCard key={podcast.ref} contentRef={podcast.ref} />
          })}
        </ContentItemsProvider>
        <div className="col-span-full flex justify-center">
          {isMounted && data && (
            <Button
              text="More podcasts"
              shadowHeight={1}
              size="sm"
              loading={isFetchingNextPage}
              disabled={!hasNextPage}
              onClick={() => fetchNextPage()}
              className="w-52 mt-5"
            />
          )}
        </div>

        {/* Initial loading spinner */}
        <Spinner
          isLoading={isFetching && !isFetchingNextPage}
          className="absolute top-24"
        />
      </GridLayout>

      {showError && (
        <ModalDialog
          onPrimaryButtonClicked={() => {
            setShowError(false)
          }}
          message={
            <>
              <p>
                We're sorry but we couldn't load podcasts from the store at this
                time.
              </p>
              <p className="mt-2">
                Please try again by refreshing your browser or contact our
                customer support.
              </p>
            </>
          }
        />
      )}
    </>
  )
}

export default StorePodcasts
StorePodcasts.whyDidYouRender = true
